<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="search-modal-root-container">
      <tab-bar
        :tabs="tabs"
        @on-tabbar-change="onTabbarChange"
        ref="tabbar"
      ></tab-bar>
      <div
        class="search-modal-main-container"
        v-if="activeTabbarIndex == 0"
        :key="0"
        style="margin-right: 200px"
      >
        <div class="page-title" style="font-size: 20px">
          {{ keyWord }}的全部付款
        </div>
        <trade-item
          v-for="item in tradeCompanyList"
          :key="item.card_log_id"
          :data-source="item"
        />
        <Page
          :total="total_count"
          :page-size="searchForm.page_size"
          @on-change="CompanyPageChange"
          style="align-self: flex-end; margin-right: 10px"
        />
      </div>
      <div
        class="search-modal-main-container"
        v-if="activeTabbarIndex == 1"
        :key="1"
        style="margin-right: 200px"
      >
        <div class="page-title" style="font-size: 20px">
          {{ keyWord }}的全部用工支出
        </div>
        <trade-item
          v-for="item in tradeContactList"
          :key="item.card_log_id"
          :data-source="item"
        />
        <Page
          :total="total_count"
          :page-size="searchForm.page_size"
          @on-change="ContactPageChange"
          style="align-self: flex-end; margin-right: 10px"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import moment from "moment";
import TabBar from "../../../components/Tabbar";
export default {
  components: {
    "tab-bar": TabBar,
  },
  data() {
    return {
      isShowModal: false,
      icons: icon,
      activeTabbarIndex: 0,
      tradeCompanyList: [],
      tradeContactList: [],
      searchForm: {
        type: 1,
        search_content: "",
        search_content_format: "",
        start_time: "Invalid date",
        end_time: "Invalid date",
        page_size: 5,
        page_index: 0,
      },
      total_count: 0,
      tabs: [
        {
          name: "付款",
        },
        {
          name: "用工相关",
        },
      ],
    };
  },
  computed: {
    keyWord() {
      if (this.searchForm.type == 1) {
        if (this.searchForm.search_content == "") {
          return "全部用户";
        }
        return this.searchForm.search_content;
      } else if (this.searchForm.type == 2) {
        return this.searchForm.search_content_format;
      } else if (this.searchForm.type == 3) {
        return this.searchForm.search_content_format;
      }
    },
  },
  methods: {
    ...mapActions({
      tradeGetListByCompanyAction: "tradeGetListByCompany",
      tradeGetListByContactAction: "tradeGetListByContact",
    }),
    open(searchForm, tab) {
      this.searchForm.type = searchForm.type;
      this.searchForm.search_content = searchForm.search_content;
      this.searchForm.search_content_format = searchForm.search_content_format;
      this.searchForm.start_time = searchForm.start_time;
      this.searchForm.end_time = searchForm.end_time;
      this.activeTabbarIndex = tab;
      this.isShowModal = true;
      this.$nextTick(()=>{
        this.$refs.tabbar.switchTo(this.activeTabbarIndex);
      })
      if (this.activeTabbarIndex == 0) {
        this.getCompanyList();
      } else {
        this.getContactList();
      }
    },
    close() {
      this.isShowModal = false;
    },
    onTabbarChange(currentIndex) {
      this.searchForm.page_index = 0;
      this.activeTabbarIndex = currentIndex;
      if (this.activeTabbarIndex == 0) {
        this.getCompanyList();
      } else {
        this.getContactList();
      }
    },
    CompanyPageChange(page) {
      this.searchForm.page_index = page - 1;
      this.getCompanyList();
    },
    ContactPageChange(page) {
      this.searchForm.page_index = page - 1;
      this.getContactList();
    },
    getCompanyList() {
      let params = Object.assign(this.searchForm, {
        start_time: moment(this.searchForm.start_time).format(
          "YYYY-MM-DD 00:00:00"
        ),
        end_time: moment(this.searchForm.end_time).format(
          "YYYY-MM-DD 23:59:59"
        ),
      });
      this.tradeGetListByCompanyAction(params)
        .then((res) => {
          for (let item of res.data) {
            item.create_time = moment(item.create_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          this.tradeCompanyList = res.data;
          this.total_count = res.total_count;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getContactList() {
      let params = Object.assign(this.searchForm, {
        start_time: moment(this.searchForm.start_time).format(
          "YYYY-MM-DD 00:00:00"
        ),
        end_time: moment(this.searchForm.end_time).format(
          "YYYY-MM-DD 23:59:59"
        ),
      });
      this.tradeGetListByContactAction(params)
        .then((res) => {
          for (let item of res.data) {
            item.create_time = moment(item.create_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          this.tradeContactList = res.data;
          this.total_count = res.total_count;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.search-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  padding: 50px;
  background-color: #fafafa;
  overflow: hidden;
}
.search-modal-main-container {
  margin-top: 50px;
  margin-left: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>