<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="trade-in-modal-root-container">
      <div class="trade-in-row">
        <div>{{ dataSource.create_time }}</div>
        <div>#服务费收入#已完成</div>
      </div>
      <my-avatar
        class="trade-in-center"
        style="margin-top: 40px"
        :img_url="dataSource.company_img_url"
        :name="dataSource.company_name"
        :size="78"
      />
      <span
        @click="goCompany"
        class="trade-in-center"
        style="
          cursor: pointer;
          font-size: 15px;
          font-weight: bold;
          margin: 10px 0;
        "
        >{{ dataSource.company_name }}</span
      >
      <fee
        class="trade-in-center"
        :amount="dataSource.salary_amount + dataSource.service_amount"
        :primarySize="18"
        :secondarySize="15"
      />
      <Divider />
      <div class="trade-in-row" style="margin-bottom: 10px">
        <div class="trade-in-key">交易ID</div>
        <div class="trade-in-val">{{ dataSource.card_log_id }}</div>
      </div>
      <div class="trade-in-row" style="margin-bottom: 10px">
        <div class="trade-in-key">付款主体</div>
        <div class="trade-in-val">{{ dataSource.company_name }}</div>
      </div>
      <div class="trade-in-row" style="margin-bottom: 10px">
        <div class="trade-in-key">付款金额</div>
        <fee
          fee-type="in"
          :amount="dataSource.salary_amount + dataSource.service_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="trade-in-center" style="margin-top: 50px">
        <Button type="primary" style="margin-right: 20px" @click="getPdf"
          >查看交易回单</Button
        >
        <Button @click="getReceipt">查看发票</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      isShowModal: false,

      icons: icon,
      dataSource: {},
    };
  },
  methods: {
    ...mapActions({
      tradeGetPdfAction: "tradeGetPdf",
      tradeGetReceiptAction: "tradeGetReceipt",
    }),
    open(dataSource) {
      this.dataSource = dataSource;
      this.isShowModal = true;
    },
    getPdf() {
      this.tradeGetPdfAction({
        card_log_id: this.dataSource.card_log_id,
      })
        .then((res) => {
          window.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getReceipt() {
      this.tradeGetReceiptAction({
        card_log_id: this.dataSource.card_log_id,
      })
        .then((res) => {
          window.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    goCompany() {
      this.$router.push({
        name: "company-detail",
        params: {
          id: this.dataSource.company_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.trade-in-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 25px 50px 30px 50px;
}
.trade-in-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.trade-in-center {
  align-self: center;
}
.trade-in-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.trade-in-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
</style>