<template>
  <div class="header-root-container">
    <span style="font-size: 15px; color: #fff; margin-right: 40px"
      >当前视角</span
    >
    <img :src="icons.ysLogo" style="height: 20px; margin-right: 10px" />
    <span style="font-size: 15px; color: #fff; margin-right: 50px"
      >游隼企业管理（山东）有限公司</span
    >

    <div class="header-menu-item" @click="showTaxModal">
      <Icon type="logo-usd" style="color: #fff; margin-right: 5px" :size="16" />
      <span style="font-size: 15px; color: #fff">税收贡献</span>
    </div>
    <div class="header-menu-item" @click="gotoCompany">
      <Icon type="md-home" style="color: #fff; margin-right: 5px" :size="18" />
      <span style="font-size: 15px; color: #fff">全部企业</span>
    </div>
    <div class="header-menu-item" @click="gotoContact">
      <Icon
        type="ios-person"
        style="color: #fff; margin-right: 5px"
        :size="20"
      />
      <span style="font-size: 15px; color: #fff">全部个人</span>
    </div>

    <div style="flex: 1"></div>

    <my-avatar
      :name="userName"
      :img_url="imgUrl"
      style="margin: 0 10px"
    ></my-avatar>
    <Poptip placement="bottom-end">
      <div class="header-menu-item">
        <div style="font-size: 15px; color: #fff; margin-right: 10px">
          {{ userName }}
        </div>
        <Icon type="ios-arrow-down" color="#fff" style="cursor: pointer" />
      </div>
      <div slot="content">
        <div style="cursor: pointer; text-align: center" @click="logout">
          退出登录
        </div>
      </div>
    </Poptip>

    <tax-modal ref="taxModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../common/icon";
import MyAvatar from "../../components/MyAvatar";
import TaxModal from "./Modal/TaxModal";
export default {
  components: {
    "my-avatar": MyAvatar,
    "tax-modal": TaxModal,
  },
  data() {
    return {
      icons: icon,
      imgUrl: null,
      userName: "",
    };
  },
  mounted() {
    this.imgUrl = localStorage.getItem("img_url");
    this.userName = localStorage.getItem("user_name");
  },
  methods: {
    ...mapActions({
      logoutAction: "logout",
    }),
    logout() {
      this.logoutAction();
      this.$router.push({ name: "login" });
    },
    showTaxModal() {
      this.$refs.taxModal.open();
    },
    gotoContact() {
      this.$router.push({
        name: "contact",
      });
    },
    gotoCompany() {
      this.$router.push({
        name: "company-list",
      });
    },
  },
};
</script>

<style>
.header-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
}
.header-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
}
</style>