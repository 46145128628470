import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import user from './modules/user'
import tax from './modules/tax'
import trade from './modules/trade'
import contact from './modules/contact'
import company from './modules/company'
import project from './modules/project'
import employer from './modules/employer'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    tax,
    trade,
    contact,
    company,
    project,
    employer
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
