import * as type from '../type';
import * as company from '../../api/company';

const state = {};

const actions = {
  companyGetCompanyList({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      company.getCompanyList(
        payload.search_content
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject(error);
      })
    })
  },
  companyGetCompanyBasic({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      company.getCompanyBasic(
        payload.company_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject(error);
      })
    })
  },
  companyGetCompanyInfo({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      company.getCompanyInfo(
        payload.company_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject(error);
      })
    })
  },
  companyGetGetService({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      company.getService(
        payload.company_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject(error);
      })
    })
  },
  companyGetTradeList({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      company.getTradeList(
        payload.company_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject(error);
      })
    })
  },
  companyGetProjectList({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      company.getProjectList(
        payload.company_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject(error);
      })
    })
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
}