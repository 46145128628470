<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="contact-detail-modal-root-container">
      <div class="contact-detail-top-container">
        <div style="display: flex; flex-direction: row; align-items: center">
          <div style="font-size: 32px; font-weight: bold; color: #000000">
            {{ userInfo.client_user_name }}
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 20px;
            "
          >
            <img :src="icons.pass" />
            <div
              style="
                font-size: 12px;
                font-weight: bold;
                color: #2b2b2b;
                margin-left: 5px;
              "
            >
              已通过个人认证
            </div>
          </div>
        </div>
        <div class="contact-detail-info-container">
          <my-avatar
            style="margin-top: 20px"
            :size="83"
            :name="userInfo.client_user_name"
          ></my-avatar>
          <div style="margin-top: 30px" class="contact-detail-info-item">
            <Icon :size="34" type="md-phone-portrait" />
            <div
              style="
                font-size: 15px;
                font-weight: bold;
                color: #5f5f5f;
                margin-left: 10px;
                min-width: 70px;
              "
            >
              手机号
            </div>
            <div style="font-size: 15px; color: #5f5f5f">
              {{ userInfo.client_user_phone }}
            </div>
          </div>
          <div style="margin-top: 20px" class="contact-detail-info-item">
            <Icon :size="34" type="md-mail" />
            <div
              style="
                font-size: 15px;
                font-weight: bold;
                color: #5f5f5f;
                margin-left: 10px;
                min-width: 70px;
              "
            >
              邮箱
            </div>
            <div style="font-size: 15px; color: #5f5f5f">--</div>
          </div>
          <div style="margin-top: 30px" class="contact-detail-info-item">
            <img :src="icons.idcard" style="width: 34px; height: 24px" />
            <div
              style="
                font-size: 15px;
                font-weight: bold;
                color: #5f5f5f;
                margin-left: 10px;
                min-width: 70px;
              "
            >
              身份证
            </div>
            <div style="font-size: 15px; color: #5f5f5f">
              {{ userInfo.client_user_idnum }}
            </div>
          </div>
          <div style="margin-top: 30px" class="contact-detail-info-item">
            <Icon type="md-document" :size="35" />
            <div
              style="
                font-size: 15px;
                font-weight: bold;
                color: #5f5f5f;
                margin-left: 10px;
                min-width: 70px;
              "
            >
              协议
            </div>
            <Button
              type="text"
              size="small"
              style="margin-top: 2px; margin-left: -10px"
              @click="viewService"
              >点击查看</Button
            >
          </div>
        </div>
      </div>
      <div class="contact-detail-bottom-container">
        <slide-tabbar
          :tabs="tabs"
          @on-change="onBarChange"
          show-bar
        ></slide-tabbar>
        <div
          v-if="currentIndex == 0"
          class="contact-detail-trade-list-container"
        >
          <trade-item
            v-for="item in logList"
            :key="item.card_log_id"
            :data-source="item"
          />
        </div>
        <div
          v-else-if="currentIndex == 1"
          class="contact-detail-project-list-container"
        >
          <project-item
            v-for="item in projectList"
            :key="item.project_id"
            :data-source="item"
            @click.native="gotoProject(item)"
          />
        </div>
        <div v-else class="contact-detail-trade-list-container">
          <div v-for="item in extInfo" :key="item.key" class="ext-row">
            <span style="margin-right: 50px; min-width: 200px">{{
              item.desc
            }}:</span>

            <img v-if="item.type == 'img'" :src="item.val" style="max-width:200px;"/>
            <span v-else>{{ item.val }}</span>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import moment from "moment";
import MyAvatar from "../../../components/MyAvatar";
import ProjectItem from "../Item/ProjectItem";
import SlideTabbar from "../../../components/SlideTabbar";
export default {
  components: {
    "my-avatar": MyAvatar,
    "project-item": ProjectItem,
    "slide-tabbar": SlideTabbar,
  },
  data() {
    return {
      isShowModal: false,
      icons: icon,
      currentIndex: 0,
      client_user_id: 0,
      userInfo: {},
      extInfo: [],
      logList: [],
      projectList: [],
      tabs: [
        {
          name: "全部交易",
        },
        {
          name: "参与的项目",
        },
        {
          name: "额外信息",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      contactGetDetailAction: "contactGetDetail",
      contactGetPayLogAction: "contactGetPayLog",
      contactGetProjectListAction: "contactGetProjectList",
      contactGetServiceAction: "contactGetService",
      contactGetExtInfoAction: "contactGetExtInfo",
    }),
    viewService() {
      this.contactGetServiceAction({
        client_user_id: this.client_user_id,
      })
        .then((res) => {
          window.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    open(id) {
      this.client_user_id = id;

      Promise.all([
        this.contactGetDetailAction({
          client_user_id: this.client_user_id,
        }),
        this.contactGetPayLogAction({
          client_user_id: this.client_user_id,
        }),
        this.contactGetProjectListAction({
          client_user_id: this.client_user_id,
        }),
        this.contactGetExtInfoAction({
          client_user_id: this.client_user_id,
        }),
      ])
        .then((values) => {
          this.userInfo = values[0];
          for (let item of values[1]) {
            item.type = 2;
          }
          for (let item of values[1]) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:ss"
            );
          }
          this.logList = values[1];
          this.projectList = values[2];
          this.extInfo = values[3];
          this.isShowModal = true;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    close() {
      this.isShowModal = false;
    },
    onBarChange(index) {
      this.currentIndex = index;
    },
    gotoProject(item) {
      this.$router.push({
        name: "project-base",
        params: {
          id: item.project_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.contact-detail-modal-root-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 50px;
  padding-left: 285px;
  background-color: #fafafa;
}
.contact-detail-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.contact-detail-info-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  margin-right: 140px;
  width: 350px;
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 20px;
}
.contact-detail-info-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 80%;
}
.contact-detail-bottom-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 140px;
}
.contact-detail-trade-list-container {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.contact-detail-project-list-container {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.ext-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
}
</style>