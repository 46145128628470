<template>
  <span>
    <span class="logo-font logo-green" :style="{'font-size':size=='small'?'30px':'48px'}">F</span>
    <span class="logo-font logo-black" :style="{'font-size':size=='small'?'30px':'48px'}">ree</span>
    <span class="logo-font logo-red" :style="{'font-size':size=='small'?'30px':'48px'}">L</span>
    <span class="logo-font logo-black" :style="{'font-size':size=='small'?'30px':'48px'}">ancer</span>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      default: "large",
      type: String,
    },
  },
};
</script>

<style scoped>
.logo-font {
  font-weight: bold;
}
.logo-green {
  color: #2d9661;
}
.logo-black {
  color: #4a4848;
}
.logo-red {
  color: #e65f5e;
}
</style>