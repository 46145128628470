import api from './config'

export function getList(
  search_content
) {
  return api.post('platform/contact/getList', {
    search_content
  })
}

export function getDetail(
  client_user_id
) {
  return api.post('platform/contact/getBasic', {
      client_user_id
  })
}

export function getPayLog(
  client_user_id
) {
  return api.post('platform/contact/getTradeList', {
      client_user_id
  })
}

export function getProjectList(
  client_user_id
) {
  return api.post('platform/contact/getProjectList', {
      client_user_id
  })
}

export function getService(
  client_user_id
) {
  return api.post('platform/contact/getService', {
      client_user_id
  })
}

export function getExtInfo(
  client_user_id
) {
  return api.post('platform/contact/getExtInfo', {
      client_user_id
  })
}