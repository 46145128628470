import api from './config'

export function getBasic(
    project_id
) {
    return api.post('platform/project/getBasic', {
        project_id
    })
}

export function getJoinedMemberList(
    project_id
) {
    return api.post('platform/project/getJoinedMemberList', {
        project_id
    })
}

export function getCompanyBase(
    project_id
) {
    return api.post('platform/project/getCompanyBase', {
        project_id
    })
}