import api from './config'

export function login(
  email,
  pwd
) {
  return api.post('platform/user/login', {
    email,
    pwd
  })
}