import api from './config'

export function getCompanyList(
  search_content
) {
  return api.post('platform/company/getCompanyList', {
    search_content
  })
}

export function getCompanyBasic(
  company_id
) {
  return api.post('platform/company/getCompanyBasic', {
    company_id
  })
}

export function getCompanyInfo(
  company_id,
) {
  return api.post('platform/company/getCompanyInfo', {
    company_id
  })
}

export function getService(
  company_id
) {
  return api.post('platform/company/getService', {
    company_id
  })
}

export function getTradeList(
  company_id
) {
  return api.post('platform/company/getTradeList', {
    company_id
  })
}

export function getProjectList(
  company_id
) {
  return api.post('platform/company/getProjectList', {
    company_id
  })
}