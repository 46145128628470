import api from './config'

export function getTotalCount(
) {
  return api.post('platform/trade/getTotalCount', {
  })
}

export function getList(
  search_content,
  start_time,
  end_time
) {
  return api.post('platform/trade/getList', {
    search_content,
    start_time,
    end_time
  })
}

export function getListByCompany(
  type,
  search_content,
  start_time,
  end_time,
  page_size,
  page_index
) {
  return api.post('platform/trade/getListByCompany', {
    type,
    search_content,
    start_time,
    end_time,
    page_size,
    page_index
  })
}

export function getListByContact(
  type,
  search_content,
  start_time,
  end_time,
  page_size,
  page_index
) {
  return api.post('platform/trade/getListByContact', {
    type,
    search_content,
    start_time,
    end_time,
    page_size,
    page_index
  })
}

export function getTax(
  card_log_id
) {
  return api.post('platform/trade/getTax', {
    card_log_id
  })
}

export function getTotalTax(
  card_log_id
) {
  return api.post('platform/trade/getTotalTax', {
    card_log_id
  })
}

export function getPdf(
  card_log_id
) {
  return api.post('platform/trade/getPdf', {
    card_log_id
  })
}

export function getReceipt(
  card_log_id
) {
  return api.post('platform/trade/getReceipt', {
    card_log_id
  })
}