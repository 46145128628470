import * as type from '../type';
import * as user from '../../api/user';

const state = {};

const actions = {
    login({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.login(
                payload.email,
                payload.pwd,
            ).then(res => {
                if (res.data.res_code == 1) {
                    commit(type.LOGIN, res.data.msg);
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    logout({
        state,
        commit,
    }, payload) {
        commit(type.LOGOUT)
    },
};

const mutations = {
    [type.LOGIN]: (state, payload) => {
        localStorage.setItem('user_name', payload.user_name);
        localStorage.setItem('user_id', payload.to_user_id);
        localStorage.setItem('img_url', payload.img_url);
        localStorage.setItem("token", payload.token);
    },
    [type.LOGOUT]: (state, payload) => {
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_id');
        localStorage.removeItem('img_url');
        localStorage.removeItem('token');
    },
};

export default {
    state,
    actions,
    mutations
}