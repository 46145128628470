<template>
  <div class="trade-item-root-container" @click="showDetail">
    <div class="trade-item-left-container">
      <my-avatar
        v-if="dataSource.type == 2"
        :size="50"
        style="margin: 15px 15px 15px 30px"
        :name="dataSource.to_client_user_name"
        :img_url="null"
      />
      <my-avatar
        v-else
        :size="50"
        style="margin: 15px 15px 15px 30px"
        :name="dataSource.company_name"
        :img_url="null"
      />
    </div>
    <div class="trade-item-main-container" v-if="dataSource.type == 1">
      <span style="font-size: 15px; font-weight: bold; color: #000000">
        {{dataSource.company_name}}
      </span>
      <span style="font-size: 12px; color: #626262;margin-top:10px;"
        >#服务费收入#</span
      >
    </div>
    <div v-else class="trade-item-main-container">
      <span style="font-size: 15px; font-weight: bold; color: #000000">
        {{ dataSource.to_client_user_name }}
        <span
          style="
            font-size: 12px;
            font-weight: 300;
            color: #626262;
            margin-left: 10px;
          "
          >身份证后4位:{{ idNumLastFourStr }}</span
        >
      </span>
      <div class="trade-item-row">
        <div style="font-size: 12px; color: #626262; margin-right: 15px">
          #用工支出#
        </div>
        <Icon type="md-pin" :size="12" />
        <span style="font-size: 12px; color: #363839">{{
          dataSource.to_client_user_position
        }}</span>
      </div>
    </div>
    <div class="trade-item-right-container">
      <fee
        :fee-type="dataSource.type == 1 ? 'in' : 'out'"
        :amount="
          dataSource.type == 1
            ? dataSource.salary_amount + dataSource.service_amount
            : dataSource.salary_amount
        "
        :primarySize="18"
        :secondarySize="15"
      ></fee>
      <div style="font-size: 12px; color: #7d7d7d">
        {{ dataSource.create_time }}
      </div>
    </div>
    <Poptip v-model="popVisible" placement="right">
      <Icon type="md-paper" @click.stop="showTax" />
      <div slot="title">{{dataSource.type == 1?'其中包含':'已扣除'}}</div>
      <div slot="content">
        <p v-html="popContent"></p>
      </div>
    </Poptip>
    <trade-in-modal ref="tradeInModal" />
    <trade-out-modal ref="tradeOutModal" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import TradeInModal from "../Modal/TradeInModal";
import TradeOutModal from "../Modal/TradeOutModal";
export default {
  name:"TradeItem",
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "trade-in-modal": TradeInModal,
    "trade-out-modal": TradeOutModal,
  },
  data() {
    return {
      popVisible: false,
      popContent: "",
    };
  },
  methods: {
    ...mapActions({
      tradeGetTaxAction: "tradeGetTax",
    }),
    showDetail() {
      if (this.dataSource.type == 1) {
        this.$refs.tradeInModal.open(this.dataSource);
      } else {
        this.$refs.tradeOutModal.open(this.dataSource);
      }
    },
    showTax() {
      this.tradeGetTaxAction({
        card_log_id: this.dataSource.card_log_id,
      })
        .then((res) => {
          this.popContent = res;
          this.popVisible = true;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
  computed: {
    idNumLastFourStr: function () {
      return this.dataSource.to_client_user_idnum.substring(
        this.dataSource.to_client_user_idnum.length - 4
      );
    },
  },
};
</script>

<style scoped>
.trade-item-root-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 1px #dddddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
}
.trade-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.trade-item-left-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.trade-item-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.trade-item-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
}
.trade-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}
</style>