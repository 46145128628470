import * as type from '../type';
import * as trade from '../../api/trade';

const state = {};

const actions = {
    tradeGetTotalCount({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getTotalCount(
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    tradeGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getList(
                payload.search_content,
                payload.start_time,
                payload.end_time
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    tradeGetListByCompany({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getListByCompany(
                payload.type,
                payload.search_content,
                payload.start_time,
                payload.end_time,
                payload.page_size,
                payload.page_index,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    tradeGetListByContact({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getListByContact(
                payload.type,
                payload.search_content,
                payload.start_time,
                payload.end_time,
                payload.page_size,
                payload.page_index,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    tradeGetTax({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getTax(
                payload.card_log_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    tradeGetTotalTax({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getTotalTax(
                payload.card_log_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    tradeGetPdf({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getPdf(
                payload.card_log_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
    tradeGetReceipt({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            trade.getReceipt(
                payload.card_log_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject();
            })
        })
    },
};

const mutations = {
};

export default {
    state,
    actions,
    mutations
}