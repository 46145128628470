<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="search-modal-root-container">
      <tab-bar
        :tabs="tabs"
        @on-tabbar-change="onTabbarChange"
        ref="tabbar"
      ></tab-bar>
      <div
        class="search-modal-main-container"
        v-if="activeTabbarIndex == 0"
        :key="0"
      >
        <div class="page-title">全部个人用户</div>
        <Input
          prefix="ios-search"
          v-model="search_contact_content"
          @keyup.enter.native="getContactList"
          placeholder="搜索"
          style="width: 250px; margin-top: 20px"
        />
        <div class="contact-list-root-container">
          <div class="contact-left-container">
            <div style="overflow-y: scroll">
              <div
                v-for="(memberItem, index) in memberMap"
                :key="index"
                style="margin-bottom: 30px"
              >
                <div
                  style="font-size: 23px; font-weight: bold; color: #3c3c3c"
                  :id="index"
                >
                  {{ index }}
                </div>
                <div class="contact-list-container">
                  <contact-item
                    style="margin: 10px"
                    v-for="item in memberItem"
                    :key="item.client_user_id"
                    :data-source="item"
                    @click.native="
                      close(3, item.client_user_id, item.client_user_name)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="contact-right-container">
            <a
              v-for="(item, index) in keyList"
              class="contact-head-item"
              :href="'#' + item"
              :key="index"
              >{{ item }}</a
            >
          </div>
        </div>
      </div>
      <div
        class="search-modal-main-container"
        v-if="activeTabbarIndex == 1"
        :key="1"
        style="margin-right: 200px"
      >
        <div class="page-title">全部企业用户</div>
        <Input
          prefix="ios-search"
          v-model="search_company_content"
          @keyup.enter.native="getCompanyList"
          placeholder="搜索"
          style="width: 250px; margin-top: 20px"
        />
        <div class="company-list-main-container">
          <company-item
            style="margin: 10px"
            v-for="item in companyList"
            :key="item.company_id"
            :showRight="false"
            :data-source="item"
            @click.native="close(2, item.company_id, item.name)"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import moment from "moment";
import * as _ from "lodash";
import * as pyHelper from "../../../utils/pyHelper";
import TabBar from "../../../components/Tabbar";
import ContactItem from "../../Contact/Item/ContactItem";
import CompanyItem from "../../Company/Item/CompanyItem";
export default {
  components: {
    "tab-bar": TabBar,
    "contact-item": ContactItem,
    "company-item": CompanyItem,
  },
  data() {
    return {
      isShowModal: false,
      icons: icon,
      activeTabbarIndex: 0,
      tabs: [
        {
          name: "个人用户",
        },
        {
          name: "企业用户",
        },
      ],
      keyList: [],
      memberMap: {},
      companyList: [],
      search_contact_content: "",
      search_company_content: "",
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      contactGetListAction: "contactGetList",
      companyGetCompanyListAction: "companyGetCompanyList",
    }),
    open(tab) {
      this.activeTabbarIndex = tab;
      this.isShowModal = true;
      if (this.activeTabbarIndex == 0) {
        this.getContactList();
      } else {
        this.getCompanyList();
      }
    },
    close(type, search_content, search_content_format) {
      this.isShowModal = false;
      this.$emit("search", {
        type,
        search_content,
        search_content_format,
      });
    },
    onTabbarChange(currentIndex) {
      this.activeTabbarIndex = currentIndex;
      if (this.activeTabbarIndex == 0) {
        this.getContactList();
      } else {
        this.getCompanyList();
      }
    },
    getContactList() {
      this.memberMap = {};
      this.keyList = [];
      this.contactGetListAction({
        search_content: this.search_contact_content,
      })
        .then((res) => {
          this.userList = res;

          let tempMap = {};
          for (let item of this.userList) {
            let firstPY = pyHelper.makePy(item.client_user_name[0])[0];
            if (this.keyList.indexOf(firstPY) == -1) {
              this.keyList.push(firstPY);
              tempMap[firstPY] = [item];
            } else {
              tempMap[firstPY].push(item);
            }
          }
          this.keyList.sort();
          for (let item of this.keyList) {
            this.memberMap[item] = tempMap[item];
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getCompanyList() {
      this.companyGetCompanyListAction({
        search_content: this.search_company_content,
      })
        .then((res) => {
          this.companyList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.search-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  padding: 50px;
  background-color: #fafafa;
  overflow: hidden;
}
.search-modal-main-container {
  margin-top: 50px;
  margin-left: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
.contact-list-root-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  overflow: hidden;
}
.contact-left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.contact-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  background: #2a2828;
  color: #ffffff;
  border-radius: 15px;
  padding: 0px 10px;
  margin-right: 40px;
  margin-bottom: 20px;
  width: 30px;
}
.contact-head-item {
  cursor: pointer;
  color: #ffffff;
  transition: all 0.2s ease;
}
.contact-head-item:hover {
  transform: scale(1.5);
}
.contact-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.company-list-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>