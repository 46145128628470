<template>
  <div class="tax-item-outer-container">
    <span
      style="
        align-self: flex-start;
        font-size: 13px;
        font-weight: 500;
        color: #868686;
      "
      >{{ dataSource.month }}</span
    >
    <div class="tax-item-root-container">
      <div class="tax-item-column">
        <span style="font-size: 13px; font-weight: bold">月度业务总金额</span>
        <fee
          style="margin-top: 10px"
          :amount="dataSource.amount"
          :primarySize="14"
          :secondarySize="14"
        />
      </div>
      <Divider type="vertical" style="margin-top: 4px" />
      <div class="tax-item-column">
        <span style="font-size: 13px; font-weight: bold">月度业务笔数</span>
        <span
          style="
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;
            color: #000;
          "
          >{{ dataSource.count }}</span
        >
      </div>
      <Divider type="vertical" style="margin-top: 4px" />
      <div class="tax-item-column">
        <span style="font-size: 13px; font-weight: bold">纳税明细：</span>
      </div>
      <div class="tax-item-column">
        <span style="font-size: 13px">个人所得税</span>
        <fee
          style="margin-top: 10px"
          :amount="dataSource.grs_amount"
          :primarySize="14"
          :secondarySize="14"
        />
      </div>
      <div class="tax-item-column">
        <span style="font-size: 13px">增值税</span>
        <fee
          style="margin-top: 10px"
          :amount="dataSource.zzs_amount"
          :primarySize="14"
          :secondarySize="14"
        />
      </div>
      <div class="tax-item-column">
        <span style="font-size: 13px">教育、城建及附加</span>
        <fee
          style="margin-top: 10px"
          :amount="dataSource.edu_amount"
          :primarySize="14"
          :secondarySize="14"
        />
      </div>
      <div style="flex: 1"></div>
      <div class="tax-item-column">
        <span style="font-size: 13px; font-weight: bold">合计纳税</span>
        <fee
          style="margin-top: 10px"
          :amount="dataSource.total_amount"
          :primarySize="18"
          :secondarySize="16"
        />
      </div>
    </div>
    <div class="tax-item-bottom-container">
      <Button
        type="text"
        @click="viewInvoice"
        style="margin-right: 10px; color: blue"
        >查看发票</Button
      >
      <!-- <Button type="text" @click="viewExecl" style="color:blue">下载excel</Button> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
  },
  methods: {
    ...mapActions({
      taxGetTaxInvoiceAction: "taxGetTaxInvoice",
    }),
    viewInvoice() {
      this.taxGetTaxInvoiceAction({
        date: this.dataSource.month_format,
      })
        .then((res) => {
          window.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    viewExecl() {},
  },
};
</script>

<style scoped>
.tax-item-outer-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.tax-item-root-container {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 0px 1px #dddddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
}
.tax-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.tax-item-column {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  align-items: center;
  min-width: 80px;
}
.tax-item-bottom-container {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
}
</style>