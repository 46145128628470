<template>
  <div class="company-item-root-container">
    <my-avatar
      style="margin-right: 20px; cursor: pointer"
      :size="50"
      :name="dataSource.name"
      :img_url="dataSource.img_url"
    />
    <span class="company-item-title">{{ dataSource.name }}</span>
    <div style="flex: 1"></div>
    <div class="company-row" v-if="dataSource.state == 2 && showRight">
      <img :src="icons.pass" />
      <span style="font-size: 12px; color: #2b2b2b; margin-left: 10px"
        >已通过企业认证</span
      >
    </div>
    <div class="company-row" v-if="dataSource.state == 1 && showRight">
      <img :src="icons.warning" />
      <span style="font-size: 12px; color: #808080; margin-left: 10px"
        >未完成企业认证</span
      >
    </div>
    <img v-if="showRight" :src="icons.rightArrow" style="margin-left: 60px" />
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
    showRight: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: icon,
    };
  },
  methods: {},
};
</script>

<style>
.company-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 14px;
  padding: 20px 20px;
  box-shadow: 0px 0px 1px #dddddd;
  cursor: pointer;
}
.company-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.company-item-title {
  font-size: 15px;
  font-weight: bold;
}
.company-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>