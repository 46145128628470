<template>
  <div class="tabbar-root-container">
    <div
      v-for="(item, index) in tabs"
      :key="index"
      class="tabbar-item-container"
      @click="change(index)"
    >
      {{ item.name }}
    </div>
    <div
      class="tabbar-item-active"
      :style="{ transform: 'translateX(' + activeIndex * 86 + 'px)' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  mounted() {
    let hrefArray = window.location.href.split("/");
    this.activeIndex = this.tabs.findIndex(
      (item) => item.path == hrefArray[hrefArray.length - 1]
    );
    if (this.activeIndex < 0) {
      this.activeIndex = 0;
    }
  },
  methods: {
    change(index) {
      this.activeIndex = index;
      this.$emit("on-tabbar-change", index);
    },
    switchTo(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped>
.tabbar-root-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  height: 40px;
  color: #000000;
  font-weight: 500;
  border-radius: 8px;
  background: #ededed;
}
.tabbar-item-container {
  width: 64px;
  height: 20px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 12px;
  cursor: pointer;
  z-index: 11;
}
.tabbar-item-active {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 72px;
  height: 30px;
  border-radius: 8px;
  background-color: white;
  transition: all 0.2s ease;
  z-index: 10;
}
</style>