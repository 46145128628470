import * as type from '../type';
import * as project from '../../api/project';

const state = {}

const actions = {
    projectGetBasic({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getBasic(
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectGetJoinedMemberList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getJoinedMemberList(
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectGetCompanyBase({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getCompanyBase(
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}