<template>
  <div class="home-root-container">
    <div class="home-header-container">
      <my-header />
      <div class="home-header-main-container">
        <span style="color: #fff; font-size: 32px; font-weight: bold"
          >灵活用工监管平台</span
        >
        <span style="color: #fff; font-size: 13px; margin-top: 10px"
          >全部交易{{ totalCount }}笔</span
        >

        <Input
          @keyup.enter.native="setContent"
          v-model="search_content_temp"
          prefix="ios-search"
          placeholder="搜索企业名称或个人名称"
          size="large"
          style="width: 350px; margin-top: 40px"
        />
      </div>
    </div>

    <div class="home-main-container">
      <div class="home-row-container">
        <span
          @click="showSearch"
          style="
            font-size: 23px;
            font-weight: bold;
            color: #15509f;
            cursor: pointer;
          "
          >{{ keyWord
          }}<span style="font-size: 23px; font-weight: bold; color: #000"
            >的交易</span
          ></span
        >
        <Icon
          type="md-close"
          :size="30"
          @click="init"
          style="cursor: pointer"
        />
        <div style="flex: 1"></div>
        <span style="margin-right: 10px"
          >{{ start_time_format }}至{{ end_time_format }}</span
        >
        <Button
          size="small"
          icon="md-calendar"
          type="text"
          @click="showTimeDrawer"
          >设置日期</Button
        >
      </div>

      <div class="home-list-container">
        <div class="home-row-container" style="margin: 0 10px">
          <span style="font-size: 12px; font-weight: bold">最近的付款</span>
          <div style="flex: 1"></div>
          <span
            @click="showSearchResult(0)"
            style="color: #417da6; font-size: 12px; cursor: pointer"
            >查看全部</span
          >
        </div>
        <trade-item
          v-for="item in tradeCompanyList"
          :key="item.card_log_id"
          :data-source="item"
        />

        <div
          class="home-row-container"
          style="margin: 0 10px; margin-top: 50px"
        >
          <span style="font-size: 12px; font-weight: bold">最近的用工相关</span>
          <div style="flex: 1"></div>
          <span
            @click="showSearchResult(1)"
            style="color: #417da6; font-size: 12px; cursor: pointer"
            >查看全部</span
          >
        </div>
        <trade-item
          v-for="item in tradeContactList"
          :key="item.card_log_id"
          :data-source="item"
        />
      </div>
    </div>

    <time-drawer ref="timeDrawer" @setTime="setTime" />
    <search-modal ref="searchModal" @search="search" />
    <search-result-modal ref="searchResultModal" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
import * as _ from "lodash";
import Header from "./Header";
import * as icon from "../../common/icon";
import TimeDrawer from "./Drawer/TimeDrawer";
import SearchModal from "./Modal/SearchModal";
import SearchResultModal from "./Modal/SearchResultModal";
export default {
  name: "Home",
  components: {
    "my-header": Header,
    "time-drawer": TimeDrawer,
    "search-modal": SearchModal,
    "search-result-modal": SearchResultModal,
  },
  data() {
    return {
      icons: icon,
      tradeCompanyList: [],
      tradeContactList: [],
      totalCount: 0,
      searchForm: {
        type: 1,
        search_content: "",
        search_content_format: "",
        start_time: "Invalid date",
        end_time: "Invalid date",
        page_size: 25,
        page_index: 0,
      },
      search_content_temp: "",
    };
  },
  mounted() {
    this.$Notice.config({
      duration: 0,
    });

    this.tradeGetTotalCountAction()
      .then((res) => {
        this.totalCount = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });

    this.getList();
  },
  computed: {
    start_time_format() {
      let result = moment(this.searchForm.start_time).format("YYYY年MM月DD日");
      return result == "Invalid date" ? "-" : result;
    },
    end_time_format() {
      let result = moment(this.searchForm.end_time).format("YYYY年MM月DD日");
      return result == "Invalid date" ? "-" : result;
    },
    keyWord() {
      if (this.searchForm.type == 1) {
        if (this.searchForm.search_content == "") {
          return "全部用户";
        }
        return this.searchForm.search_content;
      } else if (this.searchForm.type == 2) {
        return this.searchForm.search_content_format;
      } else if (this.searchForm.type == 3) {
        return this.searchForm.search_content_format;
      }
    },
  },
  methods: {
    ...mapActions({
      tradeGetTotalCountAction: "tradeGetTotalCount",
      tradeGetListAction: "tradeGetList",
      tradeGetListByCompanyAction: "tradeGetListByCompany",
      tradeGetListByContactAction: "tradeGetListByContact",
    }),
    showSearch() {
      this.$refs.searchModal.open(0);
    },
    showSearchResult(tab) {
      this.$refs.searchResultModal.open(this.searchForm, tab);
    },
    showTimeDrawer() {
      this.$refs.timeDrawer.open(
        this.searchForm.start_time,
        this.searchForm.end_time
      );
    },
    setTime(obj) {
      this.searchForm.start_time = obj.start_time;
      this.searchForm.end_time = obj.end_time;
      this.getList();
    },
    init() {
      // this.start_time = moment(moment().format("YYYY-01-01 00:00:00")).toDate();
      // this.end_time = moment(moment().format("YYYY-12-31 23:59:59")).toDate();
      this.searchForm.type = 1;
      (this.searchForm.search_content = ""), this.getList();
    },
    setContent() {
      this.searchForm.type = 1;
      this.searchForm.search_content = this.search_content_temp;
      this.getList();
    },
    getList() {
      let params = Object.assign(this.searchForm, {
        start_time: moment(this.searchForm.start_time).format(
          "YYYY-MM-DD 00:00:00"
        ),
        end_time: moment(this.searchForm.end_time).format(
          "YYYY-MM-DD 23:59:59"
        ),
      });
      this.tradeGetListByCompanyAction(params)
        .then((res) => {
          for (let item of res.data) {
            item.create_time = moment(item.create_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          this.tradeCompanyList = res.data;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
      this.tradeGetListByContactAction(params)
        .then((res) => {
          for (let item of res.data) {
            item.create_time = moment(item.create_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          this.tradeContactList = res.data;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    search(obj) {
      this.searchForm.type = obj.type;
      this.searchForm.search_content = obj.search_content;
      this.searchForm.search_content_format = obj.search_content_format;
      this.getList();
    },
  },
};
</script>

<style scoped>
.home-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f8f8f8;
  overflow-y: scroll;
}
.home-header-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 350px;
  min-height: 350px;
  background-color: #2b303b;
}
.home-header-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.home-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  padding: 50px 200px;
}
.home-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-list-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 30px;
}
</style>