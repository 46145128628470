<template>
  <my-drawer ref="dateDrawer" title="日期设置">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-datepicker title="开始日期" :rows="10" v-model="start_time" />
        <my-datepicker title="结束日期" :rows="10" v-model="end_time" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >提交</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import MyDatePicker from "../../../components/Form/MyDatePicker";
import MyDrawer from "../../../components/MyDrawer";
import moment from "moment";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-datepicker": MyDatePicker,
  },
  data() {
    return {
      start_time: new Date(),
      end_time: new Date(),
    };
  },
  methods: {
    open(start_time, end_time) {
      if (start_time == "Invalid Date") {
        start_time = moment(moment().format("YYYY/01/01 00:00:00")).toDate();
      }
      if (end_time == "Invalid Date") {
        end_time = moment(moment().format("YYYY/12/31 23:59:59")).toDate();
      }
      this.start_time = start_time;
      this.end_time = end_time;
      this.$refs.dateDrawer.open();
    },
    submit() {
      this.$emit("setTime", {
        start_time: moment(
          moment(this.start_time).format("YYYY/MM/DD 00:00:00")
        ).toDate(),
        end_time: moment(
          moment(this.end_time).format("YYYY/MM/DD 23:59:59")
        ).toDate(),
      });
      this.$refs.dateDrawer.close();
    },
  },
};
</script>

<style scoped>
</style>