import * as type from '../type';
import * as employer from '../../api/employer';

const state = {};

const actions = {
    employerGetBasic({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.getBasic(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
};

const mutations = {
};

export default {
    state,
    actions,
    mutations
}