<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="tax-modal-root-container">
      <tab-bar
        :tabs="tabs"
        @on-tabbar-change="onTabbarChange"
        ref="tabBar"
      ></tab-bar>
      <div class="tax-modal-row" style="margin-top: 50px">
        <div class="page-title">税收贡献</div>
        <Select
          v-model="currentYear"
          size="large"
          style="width: 100px; margin-left: 20px"
          @on-change="changeYear"
        >
          <Option v-for="item in yearList" :value="item" :key="item"
            >{{ item }}年</Option
          >
        </Select>
      </div>
      <div
        class="tax-modal-main-container"
        v-if="activeTabbarIndex == 0"
        :key="0"
      >
        <div v-for="(list, index) in taxInListFormat" :key="index">
          <div style="font-size: 13px; font-weight: 500; color: #868686">
            {{ index }}
          </div>
          <tax-in-item
            v-for="item in list"
            :key="item.card_log_id"
            :dataSource="item"
          />
        </div>
      </div>
      <div
        class="tax-modal-main-container"
        v-if="activeTabbarIndex == 1"
        :key="1"
      >
        <tax-out-item
          v-for="item in taxOutList"
          :key="item.id"
          :dataSource="item"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import moment from "moment";
import * as _ from "lodash";
import TaxInItem from "../Item/TaxInItem";
import TaxOutItem from "../Item/TaxOutItem";
import TabBar from "../../../components/Tabbar";
export default {
  components: {
    "tax-in-item": TaxInItem,
    "tax-out-item": TaxOutItem,
    "tab-bar": TabBar,
  },
  data() {
    return {
      isShowModal: false,
      icons: icon,
      currentYear: moment().format("YYYY"),
      yearList: [],
      activeTabbarIndex: 0,
      tabs: [
        {
          name: "销项",
        },
        {
          name: "进项",
        },
      ],
      taxOutList: [],
      taxInList: [],
    };
  },
  computed: {
    taxInListFormat: function () {
      this.taxInList.sort(function (a, b) {
        return moment(b.create_time) - moment(a.create_time);
      });
      return _.groupBy(this.taxInList, "create_time_format_month");
    },
  },
  methods: {
    ...mapActions({
      taxGetListAction: "taxGetList",
      taxGetYearListAction: "taxGetYearList",
    }),
    open() {
      this.activeTabbarIndex = 0;
      this.isShowModal = true;
      // this.$nextTick(() => {
      //   this.activeTabbarIndex = 1;
      //   this.$refs.tabBar.switchTo(this.activeTabbarIndex);
      // });
      this.getYear();
      this.getInList();
      this.getOutList();
    },
    close() {
      this.isShowModal = false;
    },
    changeYear(year) {
      if (this.activeTabbarIndex == 0) {
        this.getInList();
      } else {
        this.getOutList();
      }
    },
    onTabbarChange(currentIndex) {
      this.activeTabbarIndex = currentIndex;
      this.currentYear = moment().format("YYYY");
      this.getYear();
    },
    getYear() {
      this.taxGetYearListAction({
        type: this.activeTabbarIndex + 1,
      })
        .then((res) => {
          this.yearList = res;
          if (this.activeTabbarIndex == 0) {
            this.getInList();
          } else {
            this.getOutList();
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getInList() {
      if (!this.currentYear) {
        this.$Message.error("请选择年份");
        return;
      }
      this.taxGetListAction({
        type: 1,
        year: this.currentYear,
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            item.create_time_format_month = moment(item.create_time).format(
              "MM月 YYYY"
            );
          }
          this.taxInList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getOutList() {
      if (!this.currentYear) {
        this.$Message.error("请选择年份");
        return;
      }
      this.taxGetListAction({
        type: 2,
        year: this.currentYear,
      })
        .then((res) => {
          this.taxOutList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.tax-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  padding: 50px 200px;
  background-color: #fafafa;
  overflow: hidden;
}
.tax-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tax-modal-main-container {
  margin-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>