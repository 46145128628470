<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="trade-out-modal-root-container">
      <div class="trade-out-row">
        <div>{{ dataSource.create_time }}</div>
        <div>#用工支出#已完成</div>
      </div>
      <my-avatar
        class="trade-out-center"
        style="margin-top: 40px"
        :name="dataSource.to_client_user_name"
        :size="78"
      />
      <span
        class="trade-out-center"
        style="
          cursor: pointer;
          font-size: 15px;
          font-weight: bold;
          margin: 10px 0;
        "
        @click="showDetail(dataSource.to_client_user_id)"
        >{{ dataSource.to_client_user_name }}</span
      >
      <span
        class="trade-out-center"
        style="
          font-size: 12px;
          font-weight: 500;
          color: #5f5f5f;
          margin: 10px 0;
        "
        >身份证号后4位：{{ idNumLastFourStr }}</span
      >
      <fee
        class="trade-out-center"
        :amount="dataSource.salary_amount"
        :primarySize="18"
        :secondarySize="15"
      />
      <Divider />
      <div class="trade-out-row" style="margin-bottom: 10px">
        <div class="trade-out-key">交易ID</div>
        <div class="trade-out-val">{{ dataSource.card_log_id }}</div>
      </div>
      <div class="trade-out-row" style="margin-bottom: 10px">
        <div class="trade-out-key">付款金额</div>
        <fee
          fee-type="out"
          :amount="dataSource.salary_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="trade-out-row" style="margin-bottom: 10px">
        <div class="trade-out-key">已代扣代缴所得税</div>
        <fee
          :amount="tax"
          :primarySize="12"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="trade-out-row" style="margin-bottom: 10px">
        <div class="trade-out-key">位置</div>
        <div class="trade-out-val">
          {{ dataSource.to_client_user_position }}
        </div>
      </div>

      <div class="device-map-container">
        <div id="device-map" class="device-map"></div>
      </div>
      <!-- <div class="trade-out-key" style="margin-bottom: 10px">付款事由</div>
      <div class="trade-out-pay-reason" @click="showPlanModal">
        <div class="trade-out-pay-reason-container">
          <my-avatar
            style="margin-left: 15px; margin-right: 15px"
            img_url
            :name="dataSource.project_name"
          ></my-avatar>
          <div>
            <div style="font-size: 14px; font-weight: 500; color: #505050">
              来自{{ dataSource.project_name }}
            </div>
            <div style="font-size: 12px; font-weight: 500; color: #828282">
              编号#{{ dataSource.pay_plan_id }}#的付款计划
            </div>
          </div>
        </div>
        <img :src="icons.rightArrow" />
      </div> -->
      <div class="trade-out-center" style="margin-top: 50px">
        <Button type="primary" style="margin-right: 20px" @click="getPdf"
          >查看交易回单</Button
        >
      </div>
      <contact-detail-modal ref="contactDetailModal"></contact-detail-modal>
    </div>
  </Modal>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import ContactDetailModal from "../Modal/ContactDetailModal";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "contact-detail-modal": ContactDetailModal,
  },
  data() {
    return {
      isShowModal: false,

      icons: icon,
      dataSource: {},

      tax: 0,
      map: null,
    };
  },
  computed: {
    idNumLastFourStr: function () {
      return this.dataSource.to_client_user_idnum.substring(
        this.dataSource.to_client_user_idnum.length - 4
      );
    },
  },
  methods: {
    ...mapActions({
      tradeGetTotalTaxAction: "tradeGetTotalTax",
      tradeGetPdfAction: "tradeGetPdf",
      mapGetInfoAction: "mapGetInfo",
    }),
    showDetail(id) {
      this.$refs.contactDetailModal.open(id);
    },
    open(dataSource) {
      this.dataSource = dataSource;
      this.isShowModal = true;

      this.tradeGetTotalTaxAction({
        card_log_id: this.dataSource.card_log_id,
      })
        .then((res) => {
          this.tax = Number(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });

      setTimeout(() => {
        this.initMap();
        this.searchMap(this.dataSource.to_client_user_position);
      }, 500);
    },
    getPdf() {
      this.tradeGetPdfAction({
        card_log_id: this.dataSource.card_log_id,
      })
        .then((res) => {
          window.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getInfo() {
      this.mapGetInfoAction({
        location: this.dataSource.to_client_user_position,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    initMap() {
      this.map = new BMap.Map("device-map");
      this.map.centerAndZoom(new BMap.Point(116.404, 39.915), 11);
      this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    },
    searchMap(location) {
      var local = new BMap.LocalSearch(this.map, {
        renderOptions: { map: this.map },
      });
      local.search(location);
    },
  },
};
</script>

<style scoped>
.trade-out-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 25px 50px 30px 50px;
}
.trade-out-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.trade-out-center {
  align-self: center;
}
.trade-out-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.trade-out-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.atrade-out-pay-reason {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fbfae8;
  border-radius: 5px;
  padding: 5px 15px 5px 5px;
  cursor: pointer;
}
.trade-out-pay-reason-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}
.device-map-container {
  display: flex;
  align-self: flex-end;
  width: 300px;
  height: 300px;
}
.device-map {
  width: 100%;
  height: 100%;
}
</style>