import * as type from '../type';
import * as tax from '../../api/tax';

const state = {};

const actions = {
    taxGetYearList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            tax.getYearList(
                payload.type
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taxGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            tax.getList(
                payload.type,
                payload.year
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taxGetTaxInvoice({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            tax.getTaxInvoice(
                payload.date,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
};

const mutations = {
};

export default {
    state,
    actions,
    mutations
}