<template>
  <div class="contact-item-root-container">
    <my-avatar :size="50" :name="dataSource.client_user_name"></my-avatar>
    <div class="contact-item-info-container">
      <span style="font-size: 15px;font-weight:bold;margin-bottom:8px;">{{dataSource.client_user_name}}</span>
      <span v-if="dataSource.count==0" style="font-size:12px">Ta还没有加入任何项目</span>
      <span v-else style="font-size:12px">Ta加入了{{dataSource.project_count}}项目</span>
    </div>
  </div>
</template>

<script>
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    "my-avatar": MyAvatar,
  },
  props: {
    dataSource: Object,
  },
};
</script>

<style scoped>
.contact-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0px 0px 1px #dddddd;
  min-width: 260px;
  cursor: pointer;
}
.contact-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.contact-item-info-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 50px;
}
</style>