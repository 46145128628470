import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import Login from '../views/Login/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact/Contact.vue'),
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/Project/Project.vue'),
    children: [
      {
        path: '/project/base/:id',
        name: 'project-base',
        component: () => import('../views/Project/Base.vue'),
      },
      {
        path: '/project/invite/:id',
        name: 'project-invite',
        component: () => import('../views/Project/Invite.vue'),
      },
    ]
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/Company/Company.vue'),
    children: [
      {
        path: '/company/company-list',
        name: 'company-list',
        component: () => import('../views/Company/CompanyList.vue'),
      },
      {
        path: '/company/company-detail/:id',
        name: 'company-detail',
        component: () => import('../views/Company/CompanyDetail.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
