import api from './config'

export function getYearList(
  type
) {
  return api.post('platform/tax/getYearList', {
    type
  })
}

export function getList(
  type,
  year
) {
  return api.post('platform/tax/getList', {
    type,
    year
  })
}

export function getTaxInvoice(
  date
) {
  return api.post('platform/tax/getTaxInvoice', {
    date
  })
}