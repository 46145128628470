import { ossHost } from './env'

export const plan = ossHost + 'icon/plan.png';
export const service = ossHost + 'icon/service.png';
export const invoice = ossHost + 'icon/invoice.png';
export const contact = ossHost + 'icon/contact.png';
export const app = ossHost + 'icon/app.png';
export const queue = ossHost + 'icon/queue.png';
export const report = ossHost + 'icon/report.png';
export const dashboard = ossHost + 'icon/dashboard.png';
export const asset = ossHost + 'icon/asset.png';
export const refresh = ossHost + 'icon/refresh.png';
export const home = ossHost + 'icon/home.png';
export const add = ossHost + 'icon/add.png';
export const upArrow = ossHost + 'icon/up-arrow.png';
export const employerAvatar = ossHost + 'icon/employerAvatar.png';
export const employerBackground = ossHost + 'icon/employerBackground.png';
export const homeWhite = ossHost + 'icon/homeWhite.png';
export const count = ossHost + 'icon/count.png';
export const position = ossHost + 'icon/position.png';
export const scale = ossHost + 'icon/scale.png';
export const business = ossHost + 'icon/business.png';
export const contract = ossHost + 'icon/contract.png';
export const appPlaceholder = ossHost + 'icon/appPlaceholder.png';
export const reportPlaceholder = ossHost + 'icon/reportPlaceholder.png';
export const servicePlaceholder = ossHost + 'icon/servicePlaceholder.png';
export const edit = ossHost + 'icon/edit.png';
export const create = ossHost + 'icon/create.png';
export const pass = ossHost + 'icon/pass.png';
export const warning = ossHost + 'icon/warning.png';
export const rightArrow = ossHost + 'icon/rightArrow.png';
export const rightArrow2 = ossHost + 'icon/rightArrow2.png';
export const email = ossHost + 'icon/email.png';
export const phone = ossHost + 'icon/phone.png';
export const idcard = ossHost + 'icon/idcard.png';
export const fixed = ossHost + 'icon/fixed.png';
export const hour = ossHost + 'icon/hour.png';
export const task = ossHost + 'icon/task.png';
export const schedule = ossHost + 'icon/schedule.png';
export const accept = ossHost + 'icon/accept.png';
export const refuse = ossHost + 'icon/refuse.png';
export const doc = ossHost + 'icon/doc.png';
export const xls = ossHost + 'icon/xls.png';
export const pdf = ossHost + 'icon/pdf.png';
export const other = ossHost + 'icon/other.png';
export const taskTracker = ossHost + 'icon/taskTracker.png';
export const taskDashboard = ossHost + 'icon/taskDashboard.png';
export const archive = ossHost + 'icon/archive.png';
export const milestone = ossHost + 'icon/milestone.png';
export const milestoneNew = ossHost + 'icon/milestoneNew.png';
export const milestoneList = ossHost + 'icon/milestoneList.png';
export const payConfirm = ossHost + 'icon/payConfirm.png';
export const person = ossHost + 'icon/person.png';
export const notFound = ossHost + 'icon/notFound.png';
export const invoiceHistory = ossHost + 'icon/invoiceHistory.png';
export const milestoneType = ossHost + 'icon/milestoneType.png';
export const milestoneType2 = ossHost + 'icon/milestoneType2.png';
export const passSolid = ossHost + 'icon/passSolid.png';
export const projectLogo = ossHost + 'icon/projectLogo.png';
export const code = ossHost + 'icon/code.png';
export const assetCardLogo = ossHost + 'icon/assetCardLogo.png';
export const bookKeeping = ossHost + 'icon/bookKeeping.png';
export const boardBackground = ossHost + 'icon/boardBackground.png';
export const tongbi = ossHost + 'icon/tongbi.png';
export const ysLogo = ossHost + 'icon/ysLogo.png';
